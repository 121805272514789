const helpLinks = {
	user: {
		submitChallenge: 'user/#solution-submission',
		challengeResources: 'user/#challenge-resources',
		challengeProperties: 'user/#challenge-properties',

		pageDashboard: 'user/#dashboard',
		pageEvents: 'user/#event-overview',
		pageChallenge: 'user/#challenge-view',
		pageTheory: 'user/#theory-view',
		pageQuiz: 'user/#quiz-view',
		pageRanking: 'user/#rankings',
		pageEventDetails: 'user/#event-view',
		pageProfile: 'user/#profile-view',
		pageOwnReport: 'user/#personal-report'
	},

	teacher: {
		correct: 'teacher/#solution-list',

		pageEvents: 'teacher/#event-overview',
		pageEventDetails: 'teacher/#event-details',
		pageChallengeSolution: 'teacher/#solution-view',
		pageQuizSolution: 'teacher/#quiz-solution-view'
	},

	manager: {
		createTeam: 'manager/#team-creation',
		createClass: 'manager/#class-creation',
		createEvent: 'manager/#event-creation',
		manageEventTeams: 'manager/#team-and-user-assignment',
		manageEventUnits: 'manager/#event-units',
		manageEventUsers: 'manager/#team-and-user-assignment',

		pageEventManagement: 'manager/#event-management',
		pageEventDetails: 'manager/#event-details',
		pageTeamManagement: 'manager/#teams-management',
		pageTeamDetails: 'manager/#team-details',
		pageClassManagement: 'manager/#classes-management',
		pageClassDetails: 'manager/#class-details'
	},

	auditor: {
		pageClassesReport: 'auditor/#classes-report',
		pageClassReport: 'auditor/#class-report',
		pageTeamReport: 'auditor/#team-report',
		pageUserReport: 'auditor/#user-report'
	}
}

export const fetchHelp = (baseHelpUrl: string, role: string, key: string) => {
	const subPath = helpLinks[role][key]
	return subPath ? baseHelpUrl + subPath : baseHelpUrl
}
