import {
	addNotification,
	API_REQUEST, API_RESULT,
	removeNotification,
	saveApiResult
} from "actions/api";
import { ResourceActionType, RESOURCE_ACTIONS } from "actions/resources";
import { getCookieValue } from "auth/authUtils";
import { createResource } from "middleware/resources/createResource";
import { fetchResource } from "middleware/resources/fetchResource";
import { updateResource } from "middleware/resources/updateResource";
import { MessageType } from "models/Message";
import guid from '../helper/guid';


document.cookie = `CSRF-TOKEN=${guid()};path=/;`

const handleSuccess = (dispatch, action) => (result) => {
	action.onSuccess?.(result);
	createSuccessMessage(
		dispatch,
		action.successNotification,
		action.method
	);
}

export default middleware => next => async action => {
	const nextAction = next(action);

	if (action.onBefore) {
		action.onBefore(middleware.dispatch)
	}

	let data;
	switch(action.type) {
		case ResourceActionType.CREATE_RESOURCE:
			createResource(action, action.body, middleware.dispatch, handleSuccess(middleware.dispatch, action));
			if(!data) {
				return nextAction;
			}
			middleware.dispatch(saveApiResult(action.resource, data, ResourceActionType.RESOURCE_RESPONSE));
			return nextAction;
		case ResourceActionType.FETCH_RESOURCE:
			data = await fetchResource(action, middleware.dispatch, handleSuccess(middleware.dispatch, action));
			if(!data) {
				return nextAction;
			}
			middleware.dispatch(saveApiResult(action.resource, data, ResourceActionType.RESOURCE_RESPONSE));
			return nextAction;
		case ResourceActionType.UPDATE_RESOURCE:
			updateResource(action, action.body, middleware.dispatch, handleSuccess(middleware.dispatch, action));
			return nextAction;
	}

	if ([ API_REQUEST, ResourceActionType.FETCH_RESOURCE_OVERVIEW].includes(action.type)) {

		let result,
			response = {}
		try {
			const init = {
				method: action.method,
				body: action.nonJsonBody
					? action.nonJsonBody
					: JSON.stringify(action.body || undefined),
				headers: createHeaders(),
				credentials: 'same-origin'
			}
			response = await fetch(`${action.url}`, init)

			if (
				response.status !== 204 &&
				response.status !== 201 &&
				!action.isByteStream
			) {
				result = await response.json()
			}

			if (action.isByteStream) {
				result = await response.blob()
			}
		} catch (e) {
		} finally {
			if (action.resource || action.type === ResourceActionType.FETCH_RESOURCE_OVERVIEW) {
				if (response.ok && action.transformResult) {
					result = action.transformResult(result)
				}

				middleware.dispatch(
					saveApiResult(
						action.resource,
						response.ok ? result : undefined,
						RESOURCE_ACTIONS?.[action.type]?.responseActionType || API_RESULT
					)
				)
			}
		}

		if (response.ok) {
			if (action.onSuccess) action.onSuccess(result)
			createSuccessMessage(
				middleware.dispatch,
				action.successNotification,
				action.method
			);
		} else {
			if (action.onFailure) {
				action.onFailure(middleware.dispatch, response.status, result)
			}

			if (!action.suppressErrorNotification) {
				const snackId = guid()
				middleware.dispatch(addNotification(snackId, buildMessage(response, result)))
				window.setTimeout(
					() => middleware.dispatch(removeNotification(snackId)),
					6000
				)
			}
		}
	}

	return nextAction
}

const buildMessage = (response, result) => {
	if (response.status === 403)
		return (
			'Request Forbidden (403)' +
			(result && result.message ? `: ${result.message}` : '')
		)

	if (result && result.message) return result.message
	else
		return `${response.statusText || 'API error'} (${response.status ||
			'unknown'})`
}

const createHeaders = () => {
	const headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('X-CSRF-TOKEN', getCookieValue('CSRF-TOKEN'))
	return headers
}

const getGenericSuccessMessage = method => {
	switch (method) {
		case 'get':
			return 'Received successfully'
		case 'post':
			return 'Created successfully'
		default:
			return 'Updated successfully'
	}
}

export const createSuccessMessage = (dispatch, successNotification, method = 'get') => {
	if (!successNotification) {
		return;
	}

	const snackId = guid()
	dispatch(
		addNotification(
			snackId,
			typeof successNotification === 'string'
				? successNotification
				: getGenericSuccessMessage(method),
			MessageType.SUCCESS
		)
	)
	window.setTimeout(() => dispatch(removeNotification(snackId)), 4000)
}
