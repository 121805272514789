const NODE_ENV_PRODUCTION = "production";
const SERVER_ENV_LOCAL = "local";
const SERVER_ENV_REMOTE = "remote";
const SERVER_ENV_PRODUCTION = "production";

export function isProductionEnvironment() {
	return process.env.NODE_ENV === NODE_ENV_PRODUCTION;
}

export function isLocalServerEnvironment() {
	return process.env.SERVER_ENV === SERVER_ENV_LOCAL;
}

export function isRemoteServerEnvironment() {
	return process.env.SERVER_ENV === SERVER_ENV_REMOTE;
}

export function isProducitonServerEnvironment() {
	return process.env.SERVER_ENV === SERVER_ENV_PRODUCTION;
}
