import { resultOf } from "@hlcr/core/async";
import { keyByValue } from "@hlcr/core/enum";
import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import { Dispatch, AnyAction } from "redux";

import { BASE_URL } from "actions/resources";
import { Resource, ResourceType, RESOURCE_TYPES } from "models/ResourceUpload";
import { DetailedResourceState } from "reducers/resourceState";

import { createHeaders, processResult, Results } from "./util";

export const fetchResource = async (action: AnyAction, dispatch: Dispatch, onSuccess?: (result: DetailedResourceState) => void) => {
	const init: RequestInit = {
		method: "get",
		headers: createHeaders(),
		credentials: "same-origin",
	};
	const { basicResponse, basicError, fileMetaResponse, fileMetaError } = await fetchCommonData(action.resourceType, action.resource, init);
	const transformBasicResult = async (results: Results) => await results[0].response?.json() as Resource;
	const basic = await processResult([
		{
			response: basicResponse,
			error: basicError,
		}, 
	], transformBasicResult, HttpStatusCode.OK, dispatch, action.onFailure, action.suppressErrorNotification);
	if (!basic) {
		return undefined;
	}
	const { composeFileError, dockerManagerError, composeFileResponse, dockerManagerResponse } = await fetchDockerData(action.resourceType, action.resource, init);
	if (composeFileResponse.status === HttpStatusCode.NOT_FOUND) {
		// Newly created resource
		const result = {
			pending: false,
			basic,
			fileMeta: { name: basic.name },
			composeFile: "",
			managerConfig: "",
		} as DetailedResourceState;
		onSuccess?.(result);
		return result;
	}
	let results: Results = [
		{ response: composeFileResponse, error: composeFileError },
		{ response: dockerManagerResponse, error: dockerManagerError },
	];
	let transformResults;
	if (fileMetaResponse.status === HttpStatusCode.NOT_FOUND) {
		transformResults = async (results: Results) => {
			const dockerManager = await results[1].response?.json?.();
			return {
				pending: false,
				basic,
				fileMeta: { name: basic.name },
				composeFile: await results[0].response?.json?.(),
				managerConfig: dockerManager && JSON.stringify(dockerManager, null, 2),
			} as DetailedResourceState;
		};
	} else {
		results = [
			...results,
			{ response: fileMetaResponse, error: fileMetaError },
		];
		transformResults = async (results: Results) => {
			const dockerManager = await results[1].response?.json?.();
			return {
				pending: false,
				basic,
				fileMeta: await results[2].response?.json(),
				composeFile: await results[0].response?.json?.(),
				managerConfig: dockerManager && JSON.stringify(dockerManager, null, 2),
			} as DetailedResourceState;
		};
	}
	const result = await processResult(results, transformResults, HttpStatusCode.OK, dispatch, action.onFailure, action.suppressErrorNotification);
	if (result) {
		onSuccess?.(result);
	}
	return result;
};

async function fetchCommonData(resourceType: ResourceType, uuid: string, init: RequestInit) {
	const resourceTypePlural = `${resourceType.toLowerCase()}s`;
	const fileType = RESOURCE_TYPES[keyByValue(ResourceType, resourceType)].fileType;
	const [ [ basicError, basicResponse ], [ fileMetaError, fileMetaResponse ] ] = await Promise.all(
		[
			resultOf(fetch(`${BASE_URL}/${resourceTypePlural}/${uuid}`, init)),
			resultOf(fetch(`${BASE_URL}/${resourceTypePlural}/${uuid}/${fileType}/meta`, init)),
		],
	);
	return { basicError, fileMetaError, basicResponse, fileMetaResponse };
}

async function fetchDockerData(resourceType: ResourceType, uuid: string, init: RequestInit) {
	if (resourceType !== ResourceType.DOCKER) {
		const okResponse = { status: HttpStatusCode.OK, json: () => Promise.resolve(undefined) } as Pick<Response, "status" | "json">;
		return { composeFileError: null, dockerManagerError: null, composeFileResponse: okResponse, dockerManagerResponse: okResponse };
	}
	const [ [ composeFileError, composeFileResponse ], [ dockerManagerError, dockerManagerResponse ] ] = await Promise.all(
		[
			resultOf(fetch(`${BASE_URL}/dockers/${uuid}/compose`, init)),
			resultOf(fetch(`${BASE_URL}/dockers/${uuid}/manager`, init)),
		],
	);
	return { composeFileError, dockerManagerError, composeFileResponse, dockerManagerResponse };
}
