import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import { AnyAction, Dispatch } from "redux";

import {
	addNotification,
	removeNotification,
} from "actions/api";
import { getCookieValue } from "auth/authUtils";
import guid from "helper/guid";

export type Results = Array<{ response: Response | Pick<Response, "status" | "json">; error: null; } | { response: null | undefined; error: Response | Pick<Response, "status" | "json">; }>;

export const createHeaders = () => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	const csrfToken = getCookieValue("CSRF-TOKEN");
	csrfToken && headers.append("X-CSRF-TOKEN", csrfToken);
	return headers;
};

export async function processResult<T>(
	results: Results,
	transformResults: (results: Results) => T,
	expectedStatus: HttpStatusCode,
	dispatch: Dispatch<AnyAction>,
	onFailure?: (dispatch: Dispatch, status: HttpStatusCode, message: string) => void,
	suppressErrorNotification?: boolean,
) {
	const firstErrorIndex = results.findIndex((r) => !!r.error);
	if (firstErrorIndex >= 0) {
		handleError(dispatch, results[firstErrorIndex].response?.status ?? HttpStatusCode.INTERNAL_SERVER_ERROR, results[firstErrorIndex].error, onFailure, suppressErrorNotification);
		return undefined;
	}

	const firstUnsuccessfulIndex = results.findIndex((r) => r.response?.status !== expectedStatus);
	if (firstUnsuccessfulIndex >= 0) {
		let result;
		try {
			result = await results[firstUnsuccessfulIndex].error?.json?.();
		} catch (e) { /* */ }
		const status = results[firstUnsuccessfulIndex].response?.status ?? HttpStatusCode.INTERNAL_SERVER_ERROR;
		handleError(dispatch, status, buildMessage(results[firstUnsuccessfulIndex].response, result), onFailure, suppressErrorNotification);
		return undefined;
	}

	try {
		return transformResults(results);
	} catch(e) {
		handleError(dispatch, HttpStatusCode.INTERNAL_SERVER_ERROR, e, onFailure, suppressErrorNotification);
		return undefined;
	}
}


const handleError = (
	dispatch: Dispatch,
	status: HttpStatusCode,
	message: any,
	onFailure?: (dispatch: Dispatch, status: HttpStatusCode, message: string) => void,
	suppressErrorNotification?: boolean,
) => {
	onFailure?.(dispatch, status, message);

	if (!suppressErrorNotification) {
		const snackId = guid();
		dispatch(addNotification(snackId, message));
		window.setTimeout(() => dispatch(removeNotification(snackId)), 6000);
	}
};

const buildMessage = (response: any, result: any) => {
	if (response.status === HttpStatusCode.FORBIDDEN) {
		return `Request Forbidden (403) ${(result && result.message ? `: ${result.message}` : "")}`;
	}

	return result?.message ?? `${response.statusText || "API error"} (${response.status || "unknown"})`;
};
