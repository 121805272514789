import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "actions/api";
import { ResourceActionType } from "actions/resources";
import { ResourceBase } from "models/ResourceUpload";
import { initState } from "reducers/apiInitState";

import { ResourcesState, ResourceState, ResourceStateData } from "./resourceState";


const mergeItem = (next: ResourceBase, prev?: ResourceState) => {
	return {
		...prev,
		basic: {
			...prev?.basic,
			uuid: next.uuid,
			type: next.type,
			displayName: next.displayName,
		},
	};
};

export enum NotificationActionType {
	ADD_NOTIFICATION = "ADD_NOTIFICATION",
	REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
}

interface ResourceOverviewAction {
	type: ResourceActionType.FETCH_RESOURCE_OVERVIEW | ResourceActionType.RESOURCE_OVERVIEW_RESPONSE;
	data?: any;
}

interface ResourceAction {
	type: Exclude<ResourceActionType, ResourceActionType.FETCH_RESOURCE_OVERVIEW | ResourceActionType.RESOURCE_OVERVIEW_RESPONSE>;
	resource: string; // uuid
	data?: any;
}

interface NotificationAction {
	type: NotificationActionType;
	id: number,
	messageType: string,
	message: string,
}
type Action = ResourceAction | ResourceOverviewAction | NotificationAction;

export default (state = initState, action: Action) => {
	switch (action.type) {
		case ResourceActionType.FETCH_RESOURCE_OVERVIEW:
			return {
				...state,
				resources: {
					data: state.resources.data,
					pending: true,
				},
			};
		case ResourceActionType.RESOURCE_OVERVIEW_RESPONSE:
			return {
				...state,
				resources: {
					data: action.data?.reduce((prev: ResourcesState, item: ResourceBase) => ({ ...prev, [item.uuid]: mergeItem(item, state.resources.data?.[item.uuid]) }), {}) as ResourceStateData,
					pending: false,
				},
			};
		case ResourceActionType.FETCH_RESOURCE:
			return {
				...state,
				resources: {
					...state.resources,
					data: {
						...state.resources.data,
						[action.resource]: {
							...state.resources.data[action.resource],
							pending: true,
						},
					} as ResourceStateData,
				},
			};
		case ResourceActionType.RESOURCE_RESPONSE:
			return {
				...state,
				resources: {
					...state.resources,
					data: {
						...state.resources.data,
						[action.resource]: {
							...state.resources.data[action.resource],
							pending: false,
							...action.data,
						},
					} as ResourceStateData,
				},
			};
		case ADD_NOTIFICATION:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						id: action.id,
						messageType: action.messageType,
						message: action.message,
					},
				],
			};
		case REMOVE_NOTIFICATION: {
			const actionId = action.id;
			return {
				...state,
				notifications: [ ...state.notifications.filter(e => e.id !== actionId) ],
			};
		}
		default:
			return state;
	}
};
