import { hostnameBase, protocol } from "helper/hostname"
import defaultLogo from '../assets/img/logo.png'
import defaultLogoSmall from '../assets/img/logo-small.png'

export const FETCH_BRANDING_CONFIG = 'FETCH_BRANDING_CONFIG'
export const SET_BRANDING_CONFIG = 'SET_BRANDING_CONFIG'

export const defaultBranding = {
	websiteName: 'Hacking-Lab',
	appLogo: defaultLogo,
	appLogoSmall: defaultLogoSmall,
	realm: 'master',
	helpUrl: `${protocol}//help.${hostnameBase}/`
}

const fetchBranding = () => ({ type: FETCH_BRANDING_CONFIG })

const setBranding = payload => ({
	type: SET_BRANDING_CONFIG,
	payload
})

export default { fetchBranding, setBranding }
