const update= <T extends { [key: string]: any}>(outer: T, value: any, keys: string[]): T | undefined => {
	const key = keys.shift();
	if (!key) {
		return outer;
	}
	if (keys.length === 0) {
		if(value === undefined) {
			return outer;
		}
		return {
			...outer,
			[key]: value,
		};
	}
	return {
		...outer,
		[key]: update(outer[key], value, keys),
	};
};

export const updateState = <T extends { [key: string]: any }>(setter: (upd: (prev: T | undefined) => T | undefined) => void, value: any, ...keys: string[]) => {
	return setter((prev: T | undefined) => prev === undefined && keys.length === 0 ? undefined : update(prev || {} as T, value, keys));
};

const replace= <T extends { [key: string]: any}>(outer: T, newKey: string | undefined, value: any, keys: string[]): T | undefined => {
	const key = keys.shift();
	if (!key) {
		return outer;
	}
	if (keys.length === 0) {
		delete outer[key];
		if(value === undefined || newKey === undefined) {
			return outer;
		}
		return {
			...outer,
			[newKey]: value,
		};
	}
	return {
		...outer,
		[key]: replace(outer[key], newKey, value, keys),
	};
};

export const replaceStateProperty = <T extends { [key: string]: any }>(setter: (upd: (prev: T | undefined) => T | undefined) => void, newKey: string | undefined, value: any, ...keys: string[]) => {
	return setter((prev: T | undefined) => prev === undefined && keys.length === 0 ? undefined : replace(prev || {} as T, newKey, value, keys));
};

export const removeStateProperty = <T extends { [key: string]: any }>(setter: (upd: (prev: T | undefined) => T | undefined) => void, ...keys: string[]) => {
	return replaceStateProperty(setter, undefined, undefined, ...keys);
};

const move= <T extends { [key: string]: any}>(outer: T, newKey: string, keys: string[]): T | undefined => {
	const key = keys.shift();
	if (!key) {
		return outer;
	}
	if (keys.length === 0) {
		const value = outer[key];
		delete outer[key];
		return {
			...outer,
			[newKey]: value,
		};
	}
	return {
		...outer,
		[key]: move(outer[key], newKey, keys),
	};
};

export const moveStateProperty = <T extends { [key: string]: any }>(setter: (upd: (prev: T | undefined) => T | undefined) => void, newKey: string, ...keys: string[]) => {
	return setter((prev: T | undefined) => prev === undefined && keys.length === 0 ? undefined : move(prev || {} as T, newKey, keys));
};
