import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

export default ({ text = 'Loading data' }) => (
	<div style={containerStyle}>
		<div style={textStyle}>{text}</div>
		<CircularProgress size={22} />
	</div>
)

const containerStyle = {
	display: 'flex',
	alignItems: 'center'
}

const textStyle = {
	marginRight: 10
}
