import { Button } from "@hlcr/mui/Button";
import { GridToolbarQuickSearch } from "@hlcr/mui/DataGrid";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloudIcon from "@material-ui/icons/CloudOutlined";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ClassNameMap, makeStyles } from "@material-ui/styles";
import { DataGrid, GridColumns, GridCellParams } from "@mui/x-data-grid";
import cx from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { fetchResources } from "actions/resources";
import IconCard from "components/Cards/IconCard";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { copyToClipboard } from "helper/clipboard";
import { ResourceBase, Resource, ResourceType, RESOURCE_TYPES } from "models/ResourceUpload";
import { RootState } from "reducers";

import { ResourceIcon } from "./ResourceIcon";


export const ResourceOverview = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const resourceData = useSelector((state: RootState)=> state.api.resources.data);
	const isLoading = useSelector((state: RootState)=> state.api.resources.pending ?? true);
	const [ pageSize, setPageSize ] = useState(10);
	const [ resources, setResources ] = useState<Array<ResourceBase | Resource>>([]);
	const [ filteredRows, setFilteredRows ] = useState<Array<ResourceBase | Resource>>([]);
	useEffect(() => {
		dispatch(fetchResources());
	}, []);

	useEffect(
		() => {
			const resources = resourceData ? Object.values(resourceData).map((r) => r.basic) : [];
			setResources(resources);
			setFilteredRows(resources);
		}, [ resourceData ],
	);

	const columns = gridColumns(classes);
	return (
		<IconCard
			icon={CloudIcon}
			iconColor="purple"
			title={<CardTitle />}
			content={
				<DataGrid
					components={{ Toolbar: GridToolbarQuickSearch }}
					componentsProps={{
						toolbar: {
							rows: resources,
							columns,
							setFilteredRows,
						},
					}}
					className={classes.table}
					loading={isLoading}
					columns={columns}
					getRowId={(r) => r.uuid}
					rows={filteredRows}
					rowsPerPageOptions={[ 10,25,100 ]}
					pageSize={pageSize}
					onPageSizeChange={setPageSize}
					checkboxSelection={false}
					disableSelectionOnClick
				/>
			}
		/>
	);
};

const gridColumns = (classes: ClassNameMap):  GridColumns => [
	{
		field: "detail",
		width: 45,
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
		headerName: " ",
		renderCell: (params: GridCellParams) => {
			const isKnownType = [ ResourceType.DOCKER, ResourceType.FILE, ResourceType.VM ].includes(params.row.type);
			return isKnownType ? (
				<Link to={`/resources/${params.row.type.toLowerCase()}/${params.row.uuid}`}>
					<Button color="info" size="xs" customClass={classes.actionButton}>
						<ArrowForwardIcon className={classes.icon} />
					</Button>
				</Link>
			) : (
				<Button size="xs" customClass={cx(classes.actionButton)} disabled>
					<ArrowForwardIcon className={classes.icon} />
				</Button>
			);
		},
	},
	{
		headerName: "Type",
		description: "Type",
		field: "type",
		type: "singleSelect",
		valueOptions: Object.keys(RESOURCE_TYPES),
		width: 120,
		filterable: true,
		align: "center",
		headerAlign: "center",
		renderCell: (params: GridCellParams) => (
			<div>
				<CustomTooltip title={params.value} placement="right">
					<ResourceIcon type={params.value} />
				</CustomTooltip>
			</div>
		),
	},
	{
		field: "displayName",
		headerName: "Name",
		flex: 1,
	},
	{
		field: "uuid",
		headerName: "UUID",
		width: 350,
		renderCell: (params: GridCellParams) => (
			<span>
				<CustomTooltip title="Copy To Clipboard" placement="top">
					<Button
						onClick={() => copyToClipboard(params.value)}
						color="infoNoBackground"
						customClass={classes.copyButton}
					>
						<CopyIcon fontSize="inherit" />
					</Button>
				</CustomTooltip>
				{params.value}
			</span>
		),
	},
];

const CardTitle = () => {
	const classes = useStyles();
	const [ isDrawerOpen, setDrawerOpen ] = useState(false);

	const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

	return (
		<div className={classes.title}>
			<div>
				<Button
					onClick={toggleDrawer}
					color="infoNoBackground"
					customClass={classes.titleButton}
				>
					Create New Resource <DownIcon />
				</Button>
				{isDrawerOpen && (
					<div className={classes.drawer}>
						<Link to="/resources/vm/new" className={classes.createLink}>
							<Button color="info" customClass={classes.createButton}>
								<AddIcon fontSize="inherit" /> VM
							</Button>
						</Link>
						<Link to="/resources/file/new" className={classes.createLink}>
							<Button color="info" customClass={classes.createButton}>
								<AddIcon fontSize="inherit" /> File
							</Button>
						</Link>
						<Link to="/resources/docker/new" className={classes.createLink}>
							<Button color="info" customClass={classes.createButton}>
								<AddIcon fontSize="inherit" /> Docker
							</Button>
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles({
	title: {
		position: "relative",
		display: "flex",
		justifyContent: "flex-end",
	},
	titleButton: {
		margin: 0,
		padding: "5px 0 5px 5px",
	},
	createLink: { display: "flex" },
	createButton: {
		flex: 1,
		margin: "4px 0",
		padding: "8px 12px",
	},
	drawer: {
		position: "absolute",
		right: 0,
		display: "flex",
		flexDirection: "column",
		zIndex: 1,
		animation: "resourceOverview__drawer--in .3s ease",
	},
	toolbar: { justifyContent: "center" },
	table: { height: "calc(100vh - 245px)" },
	"@keyframes resourceOverview__drawer--in": {
		from: { opacity: 0, transform: "translateY(-35%)" },
		to: { opacity: 1, transform: "translateY(0)" },
	},
	actionButton: {
		margin: "0 0 0 5px",
		padding: 5,
	},
	icon: {
		verticalAlign: "middle",
		width: 17,
		height: 17,
		top: -1,
		position: "relative",
	},
	copyButton: {
		padding: 2,
		margin: "0 5px 0 0",
	},
});
