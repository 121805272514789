import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
// material-ui-icons
import Clear from '@material-ui/icons/Clear'
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

function CustomInput({ ...props }) {
	const {
		classes,
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		disabled,
		error,
		white,
		inputRootCustomClasses,
		success,
		helpText
	} = props

	const labelClasses = cx(classes.labelRoot, {
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error,
		[classes.labelWithAdornment]:
			inputProps?.endAdornment !== undefined
	})

	const underlineClasses = cx({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		//!inputProps || inputProps.disabled !== true
		[classes.underline]: true,
		[classes.whiteUnderline]: white
	})
	const formControlClasses = cx(classes.formControl, {
		[formControlProps && formControlProps.className]: formControlProps
	})

	const successClasses = cx(classes.feedback, classes.labelRootSuccess, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
			inputProps?.endAdornment !== undefined
	})
	const errorClasses = cx(classes.feedback, classes.labelRootError, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
			inputProps?.endAdornment !== undefined
	})
	const marginTop = cx({
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined
	})
	const inputClasses = cx(classes.input, {
		[classes.whiteInput]: white,
		...(inputProps?.inputClasses && { [inputProps.inputClasses]: inputProps.inputClasses }),
	})

	if (inputProps) {
		delete inputProps.inputClasses
		delete inputProps.startAdornment
		delete inputProps.endAdornment
	}
	const helpTextClasses = cx({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error
	})

	return (
		<FormControl {...formControlProps} className={formControlClasses}>
			{labelText !== undefined && (
				<InputLabel className={labelClasses} htmlFor={id} {...labelProps}>
					{labelText}
				</InputLabel>
			)}
			<Input
				classes={{
					input: inputClasses,
					root: marginTop,
					disabled: classes.disabled,
					underline: underlineClasses
				}}
				id={id}
				disabled={disabled}
				{...inputProps}
			/>
			{error ? (
				<Clear className={errorClasses} />
			) : success ? (
				<Check className={successClasses} />
			) : null}
			{helpText !== undefined ? (
				<FormHelperText className={helpTextClasses} id={id + '-text'}>
					{helpText}
				</FormHelperText>
			) : null}
		</FormControl>
	)
}

CustomInput.propTypes = {
	classes: PropTypes.object.isRequired,
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helpText: PropTypes.node
}

export default withStyles(customInputStyle)(CustomInput)
