import { resultOf } from "@hlcr/core/async";
import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import { AnyAction, Dispatch } from "redux";

import { BASE_URL } from "actions/resources";
import { createComposeFile, createManagerConfig, DockerAppType, Resource, ResourceType } from "models/ResourceUpload";
import { DetailedResourceState } from "reducers/resourceState";

import { updateDockerData } from "./updateResource";
import { createHeaders, processResult, Results } from "./util";


export const createResource = async (action: AnyAction, data: DetailedResourceState, dispatch: Dispatch, onSuccess?: (result: DetailedResourceState) => void) => {
	const init: RequestInit = {
		method: "post",
		headers: createHeaders(),
		credentials: "same-origin",
	};
	const { basicError, basicResponse } = await createCommonData(action.resourceType, data.basic, init);
	const transformBasicResult = async (results: Results) => await results[0].response?.json() as Resource;
	const basic = await processResult([ { response: basicResponse, error: basicError } ], transformBasicResult, HttpStatusCode.OK, dispatch, action.onFailure, action.suppressErrorNotification);

	if (!basic) {
		return undefined;
	}

	if(action.resourceType !== ResourceType.DOCKER) {
		const result = {
			pending: false,
			basic,
			fileMeta: { name: basic.name },
			composeFile: "",
			managerConfig: "",
		} as DetailedResourceState;
		onSuccess?.(result);
		return result;
	}

	const { composeFileError, dockerManagerError, composeFileResponse, dockerManagerResponse } = await createDockerData(action.resourceType, basic.uuid, basic.name, basic.displayName, init);
	const results: Results = [
		{ response: composeFileResponse, error: composeFileError },
		{ response: dockerManagerResponse, error: dockerManagerError },
	];
	
	const transformResults = async (results: Results): Promise<DetailedResourceState> => {
		const dockerManager = await results[2].response?.json?.();
		return {
			pending: false,
			basic,
			fileMeta: { name: basic.name },
			composeFile: await results[0].response?.json?.(),
			managerConfig: dockerManager && JSON.stringify(dockerManager, null, 2),
		};
	};

	const result = await processResult(results, transformResults, HttpStatusCode.CREATED, dispatch, action.onFailure, action.suppressErrorNotification);
	if(result) {
		onSuccess?.(result);
	}
	return result;
};

async function createCommonData(resourceType: ResourceType, basic: Resource, init: RequestInit) {
	const resourceTypePlural = `${resourceType.toLowerCase()}s`;
	const [ basicError, basicResponse ] = await resultOf(fetch(`${BASE_URL}/${resourceTypePlural}/`, { ...init, body: JSON.stringify(basic) }));

	return { basicError, basicResponse };
}

async function createDockerData(resourceType: ResourceType, uuid: string, name: string, displayName: string, init: RequestInit) {
	return updateDockerData(
		resourceType,
		uuid,
		createManagerConfig(uuid, DockerAppType.IDOCKER, name, displayName, false),
		createComposeFile(uuid, DockerAppType.IDOCKER, name, false),
		init,
	);
}
