import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ChangeEvent } from "react";

import CustomInput from "components/CustomInput/CustomInput";
import { copyToClipboard } from "helper/clipboard";
import {
	Resource,
	RESOURCE_TYPES,
	ResourceType,
} from "models/ResourceUpload";

import { commonStyles } from "./style";


interface ResourceBasicsProps {
	resourceType: ResourceType;
	uuid?: string;
	data?: Resource;
	hasDisplayNameError: boolean;
	displayNameHelpText: string;
	hasFileNameError: boolean;
	handleDisplayNameChange: (event: ChangeEvent<any>) => void;
	handleFileNameChange: (event: ChangeEvent<any>) => void;
}
export const ResourceBasics = ({ resourceType, uuid, data, hasDisplayNameError, displayNameHelpText, hasFileNameError, handleDisplayNameChange, handleFileNameChange }: ResourceBasicsProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const fileNameHelpText = intl.fm(RESOURCE_TYPES[resourceType].fileNameHelpText);
	const copyResourceUuidToClipboard = () => copyToClipboard(uuid);

	return (
		<div>
			{uuid && (
				<div className={classes.labelContainer}>
					<span className={classes.labelTitle}>UUID</span>
					<span>{uuid} </span>
					<Button
						onClick={copyResourceUuidToClipboard}
						color="infoNoBackground"
						customClass={classes.copyButton}
					>
						{intl.fm("common.copyToClipboard")}
					</Button>
				</div>
			)}
			<div className={classes.formSection}>
				<CustomInput
					labelText={intl.fm("label.resource.displayName")}
					inputProps={{
						value: data?.displayName || "",
						onChange: handleDisplayNameChange,
					}}
					helpText={displayNameHelpText}
					error={hasDisplayNameError}
				/>
				<CustomInput
					labelText={intl.fm("label.resource.fileName")}
					inputProps={{
						value: data?.name || "",
						onChange: handleFileNameChange,
						disabled: uuid && resourceType !== ResourceType.DOCKER,
					}}
					helpText={fileNameHelpText}
					error={hasFileNameError}
				/>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => createStyles({
	...commonStyles,
	formSection: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
	},
	copyButton: {
		padding: 5,
		margin: "0 0 0 10px",
	},
}),
);
