import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles } from "@material-ui/core";

import { fetchFile } from "actions/resources";
import {
	FileMeta ,
	ResourceType,
} from "models/ResourceUpload";

import { commonStyles } from "./style";


interface FileInformationProps {
	resourceType: ResourceType;
	uuid: string;
	data: FileMeta;
}
export const FileInformation = ({ resourceType, uuid, data }: FileInformationProps) => {
	const classes = useStyles();
	const intl = useIntl();

	if(!data) {
		return <div>{intl.fm("common.noUploadFound")}</div>;
	}

	return (
		<div>
			<div className={classes.labelContainer}>
				<span className={classes.labelTitle}>FILE NAME</span>
				{data.name}
			</div>
			<div>
				<span className={classes.labelTitle}>MD5 HASH</span>
				{data.hash?.toLowerCase()}
			</div>
			<a href={fetchFile(resourceType, uuid)}>
				<Button color="rose">
					{intl.fm("common.download")}
				</Button>
			</a>
		</div>
	);
};

const useStyles = makeStyles(commonStyles);
