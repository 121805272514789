
import { SvgIconProps } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import BuildIcon from "@material-ui/icons/Build";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import ComputerIcon from "@material-ui/icons/Computer";
import { forwardRef } from "react";

import { ResourceType } from "models/ResourceUpload";


interface ResourceIconProps extends SvgIconProps {
	type: any;
}
export const ResourceIcon = forwardRef(({ type, ...rest }: ResourceIconProps, ref: any) => {
	switch(type) {
		case ResourceType.DOCKER: return <CloudQueueIcon ref={ref} {...rest} />;
		case ResourceType.FILE: return <AttachmentIcon ref={ref} {...rest} />;
		case ResourceType.VM: return <ComputerIcon ref={ref} {...rest} />;
		default: return <BuildIcon ref={ref} {...rest} />;
	}
});

ResourceIcon.displayName = "ResourceIcon";
