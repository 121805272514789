import * as ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";

import SnackbarSpawner from "components/Snackbar/SnackbarSpawner";
import { en } from "localization/messages/en";
import apiMiddleware from "middleware/api";
import brandingMiddleware from "middleware/branding";
import { isProductionEnvironment } from "ProcessEnvironment";
import reducer from "reducers";
import { indexRoutes } from "routes/indexRoutes";


declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
		

const composeEnhancers = (!isProductionEnvironment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancers = composeEnhancers(applyMiddleware(apiMiddleware, brandingMiddleware));

export const createApp = () => {
	ReactDOM.render(
		<Provider store={createStore(reducer, enhancers)}>
			<IntlProvider messages={en} locale={"en"} defaultLocale="en">
				<SnackbarSpawner />
				<Router>
					<Switch>
						{indexRoutes.map((prop, key) => (
							<Route path={prop.path} component={prop.component} key={key} />
						))}
					</Switch>
				</Router>
			</IntlProvider>
		</Provider>,
		document.getElementById("root"),
	);	
};
