import React from 'react'
import PropTypes from 'prop-types'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import { withStyles } from '@material-ui/core/styles'
import noDataStyle from 'assets/jss/material-dashboard-pro-react/components/noDataStyle'

const NoData = ({ text = 'no data', Icon = CloudOffIcon, classes }) => (
	<div className={classes.container}>
		<Icon className={classes.icon} />
		<div className={classes.text}>{text}</div>
	</div>
)

NoData.propTypes = {
	classes: PropTypes.object.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	icon: PropTypes.func
}

export default withStyles(noDataStyle)(NoData)
