import { keyByValue } from "@hlcr/core/enum";
import { Button } from "@hlcr/mui/Button";
import { moveStateProperty, removeStateProperty, updateState } from "@hlcr/ui/state";
import { makeStyles, SvgIconProps } from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import UploadIcon from "@material-ui/icons/CloudUpload";
import FolderIcon from "@material-ui/icons/Folder";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { createResource, fetchResource, updateResource } from "actions/resources";
import IconCard from "components/Cards/IconCard";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import {
	RESOURCE_TYPES,
	ResourceType,
} from "models/ResourceUpload";
import { RootState } from "reducers";
import { DetailedResourceState } from "reducers/resourceState";
import { ApplicationSettings } from "views/ResourceUpload/ApplicatonSettings";

import { ResourceIcon } from "./ResourceIcon";
import { FileInformation } from "./ResourceUpload/FileInformation";
import { FileUpload } from "./ResourceUpload/FileUpload";
import { PropertiesForm } from "./ResourceUpload/PropertiesForm";
import { ResourceBasics } from "./ResourceUpload/ResourceBasics";
import { commonStyles } from "./ResourceUpload/style";


interface ToolbarProps {
	isLoading: boolean;
	saveResource: () => void;
}

const Toolbar = ({ isLoading, saveResource }: ToolbarProps) => {
	const classes = useStyles();

	return <div className={classes.toolbar}>
		<Button
			onClick={saveResource}
			color="rose"
			disabled={isLoading}
		>
			Save Resource
		</Button>
	</div>;
};

interface ResourceUploadParams {
	type: ResourceType;
	uuid: string;
}

export const ResourceUpload = ({ match: { params: { type, uuid } }, history: { push } }: RouteComponentProps<ResourceUploadParams>) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ fileNameError, setFileNameError ] = useState(false);
	const [ displayNameError, setDisplayNameError ] = useState(false);
	const [ displayNameHelpText, setDisplayNameHelpText ] = useState("");
	const [ resource, setResourceObject ] = useState<DetailedResourceState>();

	const isLoading = useSelector((state: RootState) => !!uuid && (state.api.resources.data[uuid]?.pending ?? true));
	const persistedResource = useSelector((state: RootState) => state.api.resources.data[uuid]);

	const downloadRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (isLoading !== false) {
			return;
		}
		setResourceObject(persistedResource as DetailedResourceState);
	}, [ isLoading, persistedResource ]);

	const resourceType = keyByValue(ResourceType, type.toUpperCase() as ResourceType);
	const saveResource = () => {
		let validDisplayName = false;
		let validFileName = false;

		if (!resource) {
			return;
		}

		if (resource.basic.displayName) {
			setDisplayNameError(false);
			setDisplayNameHelpText("");
			validDisplayName = true;
		} else {
			setDisplayNameError(true);
			setDisplayNameHelpText("Not empty!");
		}

		if (resource.basic.name && RESOURCE_TYPES[resourceType].nameRegex.test(resource.basic.name)) {
			setFileNameError(false);
			validFileName = true;
		} else {
			setFileNameError(true);
		}

		if (!validFileName || !validDisplayName) {
			return;
		}

		if (uuid) {
			dispatch(
				updateResource(
					type.toUpperCase() as ResourceType,
					uuid,
					resource,
				),
			);
		} else { // TODO
			dispatch(
				createResource(
					type,
					resource,
					(uuid: string) => push(`/resources/${type}/${uuid}`),
				),
			);
		}
	};


	useEffect(() => {
		if (uuid) {
			dispatch(fetchResource(type.toUpperCase() as ResourceType, uuid));
		}
	}, [ type, uuid ]);


	// Component Unmount
	useEffect(() => {
		const ref = downloadRef.current;
		return () => {
			const hiddenLink = ref;
			if (hiddenLink && hiddenLink.href !== "") {
				window.URL.revokeObjectURL(hiddenLink.href);
			}
		};
	}, [ downloadRef ]);

	const shouldShowDockerFiles = () => isLoading === false && resource && uuid && type === ResourceType.DOCKER.toLowerCase();

	const shouldShowResourceManagerProperties = () => uuid && type === ResourceType.VM.toLowerCase();

	const shouldShowUserProperties = () => uuid && type !== ResourceType.FILE.toLowerCase();
	const setComposeFile = (text: string) => updateState(setResourceObject, text, "composeFile");
	const setDockerManagerConfig = (text: string) => updateState(setResourceObject, text, "managerConfig");
	const setBuildHierarchy = (value: number) => updateState(setResourceObject, value, "basic", "hierarchy");

	const onDisplayNameChange = (event: ChangeEvent<any>) => updateState(setResourceObject, event.target.value, "basic", "displayName");
	const onFileNameChange = (event: ChangeEvent<any>) => updateState(setResourceObject, event.target.value, "basic", "name");
	const hashApplied = (hash: string) => updateState(setResourceObject, hash, "fileMeta", "hash");

	const propertyUpdateCallbacks = (prop: "userProperties" | "resourceManagerParameters") => {
		return {
			setProperty: (key: string, value: string) => updateState(setResourceObject, value, "basic", prop, key),
			replaceProperty: (oldKey: string, newKey: string) => moveStateProperty(setResourceObject, newKey, "basic", prop, oldKey),
			removeProperty: (key: string) => removeStateProperty(setResourceObject, "basic", prop, key),
		};
	};

	return (
		<div className={classes.container}>
			<Toolbar isLoading={isLoading} saveResource={saveResource} />
			<IconCard
				icon={(props: SvgIconProps) => <ResourceIcon type={type.toUpperCase()} {...props} />}
				iconColor="purple"
				title={
					isLoading
						? (<LoadingSpinner text={type.toUpperCase()} />)
						: type
							? (type.toUpperCase())
							: ("RESOURCE")
				}
				content={<ResourceBasics
					resourceType={type.toUpperCase() as ResourceType}
					uuid={uuid}
					data={resource?.basic}
					hasDisplayNameError={displayNameError}
					displayNameHelpText={displayNameHelpText}
					hasFileNameError={fileNameError}
					handleDisplayNameChange={onDisplayNameChange}
					handleFileNameChange={onFileNameChange}
				/>}
			/>
			{uuid && (
				<div>
					<IconCard
						icon={FolderIcon}
						iconColor="purple"
						title="File in Object Store"
						content={resource && <FileInformation resourceType={type} uuid={uuid} data={resource.fileMeta} />}
					/>
					<IconCard
						icon={UploadIcon}
						iconColor="purple"
						title="Upload New File"
						content={<FileUpload resourceType={type} uuid={uuid} applyHash={hashApplied} />}
					/>
				</div>
			)}
			{shouldShowDockerFiles() && (
				<div>
					<IconCard
						icon={BuildIcon}
						iconColor="purple"
						title="Application Settings"
						content={<ApplicationSettings
							uuid={uuid}
							resourceName={resource!.basic.name}
							displayName={resource!.basic.displayName}
							composeFile={resource!.composeFile}
							setComposeFile={setComposeFile}
							dockerManagerConfig={resource!.managerConfig}
							setDockerManagerConfig={setDockerManagerConfig}
							hierarchy={resource!.basic.hierarchy!}
							setHierarchy={setBuildHierarchy}
						/>}
					/>
				</div>
			)}
			{shouldShowResourceManagerProperties() && (
				<IconCard
					icon={BuildIcon}
					iconColor="purple"
					title="Resource Manager Properties"
					content={<PropertiesForm properties={resource?.basic.resourceManagerParameters} {...propertyUpdateCallbacks("resourceManagerParameters")} />}
				/>
			)}
			{shouldShowUserProperties() && (
				<IconCard
					icon={BuildIcon}
					iconColor="purple"
					title="User Properties"
					content={<PropertiesForm properties={resource?.basic.userProperties} {...propertyUpdateCallbacks("userProperties")} />}
				/>
			)}
			<Toolbar isLoading={isLoading} saveResource={saveResource} />
		</div>
	);
};

const useStyles = makeStyles({
	...commonStyles,
	container: { overflow: "visible" },
	toolbar: { float: "right" },
});
