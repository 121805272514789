import { createStyles, makeStyles } from "@material-ui/core";
import { ChangeEvent, PropsWithChildren, useEffect, useState } from "react";

import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomCheckbox from "components/CustomSwitch/CustomCheckbox";
import { AppLinkProtocol, createComposeFile, createManagerConfig, DockerAppType } from "models/ResourceUpload";


interface ApplicationSettingsProps {
	uuid: string;
	resourceName: string;
	displayName: string;
	composeFile: string;
	setComposeFile: (text: string) => void;
	dockerManagerConfig: string
	setDockerManagerConfig: (text: string) => void;
	hierarchy: number;
	setHierarchy: (value: number) => void;
}
export const ApplicationSettings = ({
	uuid,
	resourceName,
	displayName,
	composeFile,
	setComposeFile,
	dockerManagerConfig,
	setDockerManagerConfig,
	hierarchy,
	setHierarchy,
}: ApplicationSettingsProps) => {
	const [ appType, setAppType ] = useState(composeFile === "" && dockerManagerConfig === "" ? DockerAppType.IDOCKER : DockerAppType.CUSTOM);
	const [ hasDynamicFlag, setHasDynamicFlag ] = useState<boolean>(false);
	
	const adjustConfig = (
		{ appType, useDynamicFlag, protocol, port, linkProtocol }: { appType: DockerAppType, useDynamicFlag?: boolean, protocol?: "http" | "https", port?: number, linkProtocol?: AppLinkProtocol },
	) => {
		let config;
		try {
			config = JSON.parse(dockerManagerConfig);
		} catch(e) {
			config = {};
		}
		const dynamicFlag = useDynamicFlag ?? config.challengeType === "dynamicGN";
		const appPort = port ?? config.port ?? 80;
		const appProtocol = protocol ?? config.protocol ?? "http";
		const appLinkProtocol = linkProtocol ?? config.link ?? "tcp";
		setComposeFile(createComposeFile(uuid, appType, resourceName, dynamicFlag, appProtocol, appPort, config.externalNet));
		setDockerManagerConfig(createManagerConfig(uuid, appType, resourceName, displayName, dynamicFlag, appProtocol, appPort, appLinkProtocol));
	};

	useEffect(() => {
		composeFile === "" && dockerManagerConfig === "" && adjustConfig({ appType: DockerAppType.IDOCKER });
	}, [ uuid ]);

	const onHasDynamicFlagChange = (event: ChangeEvent<any>) => {
		setHasDynamicFlag(event.target.checked);
		adjustConfig({ appType, useDynamicFlag: event.target.checked });
	};

	const onAppTypeChanged = (event: ChangeEvent<any>) => {
		const type = event.target.value;
		setAppType(type);
		if (type === DockerAppType.CUSTOM) {
			return;
		}
		adjustConfig({ appType });
	};
	return (
		<div>
			<table width="100%">
				<tbody>
					<TableLabel title="Application Type">
						<CustomSelect
							title="Application Type"
							idField={"value"}
							displayField={"text"}
							list={[
								{ value: DockerAppType.IDOCKER, text: "Web Application (idocker)" },
								{ value: DockerAppType.RDOCKER, text: "Network Application (rdocker)" },
								{ value: DockerAppType.CUSTOM, text: "Custom Application" },
							]}
							valueKey={appType}
							onSelect={onAppTypeChanged}
						/>
					</TableLabel>
					{(appType !== DockerAppType.CUSTOM && <TableLabel title="Dynamic Goldnugget">
						<CustomCheckbox
							label={""}
							checked={hasDynamicFlag}
							onChange={onHasDynamicFlagChange}
						/>
					</TableLabel>)}
					<ProtocolSettings
						appType={appType}
						composeFile={composeFile}
						setComposeFile={setComposeFile}
						dockerManagerConfig={dockerManagerConfig}
						setDockerManagerConfig={setDockerManagerConfig}
						hierarchy={hierarchy}
						setHierarchy={setHierarchy}
						adjustConfig={adjustConfig}
					/>
				</tbody>
			</table>
		</div>
	);
};

interface TableLabelProps {
	title: string;
}

const TableLabel = ({ children, title }: PropsWithChildren<TableLabelProps>) => {
	const classes = useStyles();

	return (
		<tr>
			<td className={classes.tableKey}>{title}</td>
			<td className={classes.tableValue}>{children}</td>
		</tr>
	);
};

interface ProtocolSettingsProps {
	appType: DockerAppType;
	hierarchy: number;
	setHierarchy: (value: number) => void;
	composeFile: string;
	setComposeFile: (text: string) => void;
	dockerManagerConfig: string
	setDockerManagerConfig: (text: string) => void;
	adjustConfig: (p: { appType: DockerAppType, useDynamicFlag?: boolean, protocol?: "http" | "https", port?: number, linkProtocol?: AppLinkProtocol }) => void;
}
const ProtocolSettings = ({ appType, hierarchy, setHierarchy, composeFile, setComposeFile, dockerManagerConfig, setDockerManagerConfig, adjustConfig }: ProtocolSettingsProps) => {
	const classes = useStyles();

	const [ appPort, setAppPort ] = useState<number>(80);
	const [ appProtocol, setAppProtocol ] = useState<string>("http");


	useEffect(() => {
		if (appType === DockerAppType.CUSTOM) { return; }
		setAppProtocol(appType === DockerAppType.IDOCKER ? "http" : "tcp");
	}, [ appType ]);

	const onAppPortChange = (event: ChangeEvent<any>) => {
		setAppPort( event.target.value);
		if(appType === DockerAppType.IDOCKER) {
			adjustConfig({ appType, port: event.target.value });
		} else {
			adjustConfig({ appType, linkProtocol: event.target.value });
		}
	};
	const onAppProtocolChange = (event: ChangeEvent<any>) => {
		setAppProtocol(event.target.value);
		adjustConfig({ appType, protocol: event.target.value });
	};
	const onComposeFileChange = (event: ChangeEvent<any>) => setComposeFile(event.target.value);
	const onDockerManagerConfigChange = (event: ChangeEvent<any>) => setDockerManagerConfig(event.target.value);
	
	switch (appType) {
		case DockerAppType.IDOCKER:
		case DockerAppType.RDOCKER:
			return <>
				<TableLabel title="Protocol">
					<CustomSelect
						title="Protocol"
						idField={"value"}
						displayField={"value"}
						list={appType === DockerAppType.IDOCKER
							? [ { value: "http" }, { value: "https" } ]
							: [ { value: "tcp" }, { value: "udp" } ]
						}
						valueKey={appProtocol}
						onSelect={onAppProtocolChange}
					/>
				</TableLabel>
				<TableLabel title="Port">
					<CustomInput
						className={classes.managerFormControl}
						type="number"
						inputProps={{
							value: appPort,
							onChange: onAppPortChange,
						}}
					/>
				</TableLabel>
			</>;
		case DockerAppType.CUSTOM:
			return <>
				<TableLabel title="Build Hierarchy">
					<CustomSelect
						title="Hierarchy"
						idField={"value"}
						displayField={"value"}
						list={[ { value: 1 }, { value: 2 }, { value: 3 } ]}
						valueKey={hierarchy}
						onSelect={event => {
							const newValue = event.target.value as number;
							setHierarchy(newValue);
						}}
					/>
				</TableLabel>
				<TableLabel title={"Compose File"}>
					<textarea
						className={classes.textarea}
						value={composeFile}
						onChange={onComposeFileChange}
					/>

				</TableLabel>
				<TableLabel title={"Docker Manager"}>
					<textarea
						className={classes.textarea}
						value={dockerManagerConfig}
						onChange={onDockerManagerConfigChange}
					/>
				</TableLabel>
			</>;
		default:
			return null;
	}
};

const useStyles = makeStyles(() => createStyles({
	tableKey: {
		width: "1%",
		whiteSpace: "nowrap",
		padding: "6px 6px 6px 0",
		fontSize: "1.2em",
		fontWeight: 700,
		opacity: 0.4,
	},
	tableValue: {
		widht: "auto",
		padding: "6px 0",
	},
	managerFormControl: {
		margin: 0,
		padding: 0,
	},
	textarea: {
		display: "block",
		height: "18em",
		minHeight: "10em",
		width: "100%",
		minWidth: "100%",
		maxWidth: "100%",
		padding: 10,
		background: "transparent",
		border: "1px solid #ccc",
		borderRadius: 3,
		outline: "none",
		transition: ".1s border-color",
		"&:focus": {
			border: `2px solid ${primaryColor}`,
			padding: 9,
			transition: ".5s border-color",
		},
	},
}));
