import { Button } from "@hlcr/mui/Button";
import { makeStyles } from "@material-ui/core";

import { KeyValueInputField } from "views/ResourceUpload/KeyValueInputField";

interface PropertiesFormProps {
	properties?: { [key: string]: string };
	setProperty: (key: string, value: any) => void;
	replaceProperty: (oldKey: string, newKey: string) => void;
	removeProperty: (key: string) => void;
}
export const PropertiesForm = ({ properties, setProperty, replaceProperty, removeProperty }: PropertiesFormProps) => {
	const classes = useStyles();
	if(!properties) {
		return null;
	}

	const onAddClick = () => {
		const count = Object.keys(properties).length;
		setProperty(`Key${count}`, `Value${count}`);
	};
	return (
		<div>
			{Object.keys(properties).map((k, index) => (
				<KeyValueInputField
					key={index}
					oldKey={k}
					oldValue={properties[k]}
					removeProperty={removeProperty}
					setProperty={setProperty}
					replaceProperty={replaceProperty}
				/>
			))}
			<div>
				<Button
					onClick={onAddClick}
					color="info"
					customClass={classes.addButton}
				>
					Add New Entry
				</Button>
			</div>
		</div>
	);
};


const useStyles = makeStyles({
	addButton: {
		margin: 0,
		padding: "10px",
	},
});
