import { Messages } from "localization/messages";

export const en: Messages = {
	"mui.upload.error.file-too-large": "Maximum {type} size is {maxFileSizeInMB} MB. Try again!",
	"mui.upload.error.file-invalid-type": "Please only use gif, png and jpg images.",
	"common.copyToClipboard": "Copy To Clipboard",
	"common.labels.collapseSidebar": "Collapse Sidebar",
	"common.noUploadFound": "No upload found",
	"common.download": "Download",
	"navigation.entries.help": "Help",
	"navigation.entries.user.account": "Account",
	"navigation.entries.user.logout": "Logout",
	"resource.type.file.fileNameHelpText": "Allowed pattern: `/^[A-Za-z0-9\\-_]+\\.[A-Za-z0-9.]+$/` - WITH extension!",
	"resource.type.vm.fileNameHelpText": "Allowed pattern: `/^[A-Za-z0-9\\-_]+[^. ]$/` - NO extension!",
	"resource.type.docker.fileNameHelpText": "Allowed pattern: `^[a-z0-9]+(-[a-z0-9]+)*$` - NO extension!",
	"label.resource.displayName": "Display Name*",
	"label.resource.fileName": "File Name*",
	"upload.status.idle.title": "Idle",
	"upload.status.waitingForUser.title": "Press \"UPLOAD FILE\" to start",
	"upload.status.init.title": "Initializing...",
	"upload.status.uploading.title": "{uploadProgress}%",
	"upload.status.waitingForHash.title": "Uploaded! Waiting for hash calculation...",
	"upload.status.committing.title": "Uploaded! Committing hash...",
	"upload.status.done.title": "All Done!",
	"upload.status.error.title": "Error! (see console for details)",
	"upload.status.aborted.title": "Aborted!",
	"upload.status.timeout.title": "A timeout has occurred!",
};

