export async function resultOf<T>(promise: Promise<T>): Promise<[null, T]>;
export async function resultOf<T>(promise: Promise<T>): Promise<[any]>;
export async function resultOf<T>(promise: Promise<T>) {
	try {
		const result = await promise;
		return [ null, result ];
	} catch (err) {
		return [ err || "(Promise was rejected without an error)" ];
	}
}
