//
import { combineReducers } from 'redux'
import api from './apiReducer'
import branding from './brandingReducer'

const reducer = combineReducers({
	api,
	branding
})

export default reducer
