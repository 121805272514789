import { resourceInitState } from "reducers/resourceState";

interface Notification {
	id: number;
	messageType: string;
	message: string;
}

export const initState = {
	notifications: [] as Notification[],
	resources: { ...resourceInitState },
};
