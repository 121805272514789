import { createStyles } from "@material-ui/styles";


export const commonStyles = createStyles({
	labelContainer: {
		display: "flex",
		alignItems: "baseline",
	},
	labelTitle: {
		display: "inline-block",
		marginRight: 6,
		fontSize: "1.2em",
		fontWeight: 700,
		opacity: 0.4,
	},
});
