import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import CloudIcon from "@material-ui/icons/CloudOutlined";

import { ResourceOverview } from "views/ResourceOverview";
import { ResourceUpload } from "views/ResourceUpload";

export const dashboardRoutes = [
	{
		path: "/resources",
		name: "Resource Overview",
		icon: CloudIcon,
		component: ResourceOverview,
		needsRole: HackingLabRole.RESOURCES,
		hasSidebarLink: true,
		breadcrumb: "Overview",
	},
	{
		path: "/resources/:type/new",
		name: "Upload",
		component: ResourceUpload,
		needsRole: HackingLabRole.RESOURCES,
		hasSidebarLink: false,
		breadcrumb: "Create",
	},
	{
		path: "/resources/:type/:uuid",
		name: "Upload",
		component: ResourceUpload,
		needsRole: HackingLabRole.RESOURCES,
		hasSidebarLink: false,
		breadcrumb: "Edit",
	},
	{
		redirect: true,
		path: "/",
		pathTo: "/resources",
		name: "",
	},
];
