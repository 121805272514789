import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HelpIcon from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle'
import cx from 'classnames'
import HeaderLinks from 'components/Header/HeaderLinks'
import PerfectScrollbar from 'perfect-scrollbar'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { doLogout, getCurrentUsername } from "auth/authUtils"
import { withIntl } from "@hlcr/ui/Intl";
import { connect } from 'react-redux'
import brandingActions from '../../actions/branding'

let ps

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			})
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy()
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		)
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openAvatar: false,
			openManagerMenu: this.activeRoute('/manager'),
			miniActive: true
		}
		this.activeRoute.bind(this)
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.startsWith(routeName)
	}
	openCollapse(collapse) {
		const st = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	allowMinified() {
		const { width } = this.props
		switch (width) {
			case 'xs':
			case 'sm':
				return false
			case 'md':
			case 'lg':
			case 'xl':
			default:
				return true
		}
	}

	render() {
		const {
			classes,
			color,
			logo,
			logoMini,
			realm,
			logoText,
			logoBottom,
			logoMiniBottom,
			logoTextBottom,
			image,
			routes,
			bgColor,
			rtlActive,
			miniActive,
			intl
		} = this.props
		const stateMiniActive = this.state.miniActive
		const isMiniActive = this.allowMinified() && miniActive && stateMiniActive

		const itemText = cx(classes.itemText, {
			[classes.itemTextMini]: isMiniActive,
			[classes.itemTextMiniRTL]: rtlActive && isMiniActive,
			[classes.itemTextRTL]: rtlActive
		})

		const collapseItemText = cx(classes.collapseItemText, {
			[classes.collapseItemTextMini]: isMiniActive,
			[classes.collapseItemTextMiniRTL]: rtlActive && isMiniActive,
			[classes.collapseItemTextRTL]: rtlActive
		})

		const userWrapperClass = cx(classes.user, {
			[classes.whiteAfter]: bgColor === 'white'
		})

		const caret = cx(classes.caret, {
			[classes.caretRTL]: rtlActive
		})

		const collapseItemMini = cx(classes.collapseItemMini, {
			[classes.collapseItemMiniRTL]: rtlActive
		})

		const itemIcon = cx(classes.itemIcon, {
			[classes.itemIconRTL]: rtlActive
		})

		const user = (
			<div className={userWrapperClass}>
				<div className={classes.photo}>
					<AccountCircleIcon className={classes.avatarIcon} />
				</div>
				<List className={classes.list}>
					<ListItem className={cx(classes.item, classes.userItem)}>
						<NavLink
							to={'#'}
							className={cx(classes.itemLink, classes.userCollapseButton)}
							onClick={() => this.openCollapse('openAvatar')}
						>
							<ListItemText
								primary={getCurrentUsername()}
								secondary={
									<b
										className={cx(caret, classes.userCaret, {
											[classes.caretActive]: this.state.openAvatar
										})}
									/>
								}
								disableTypography={true}
								className={cx(itemText, classes.userItemText)}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={cx(classes.list, classes.collapseList)}>
								<ListItem className={classes.collapseItem}>
									<a
										href={`${window.keycloak.authServerUrl}/realms/${realm}/account/`}
										target="_blank"
										rel="noopener noreferrer"
										className={cx(classes.itemLink, classes.userCollapseLinks)}
									>
										<span className={collapseItemMini}>
											<SettingsIcon />
										</span>
										<ListItemText
											primary={intl.fm('navigation.entries.user.account')}
											disableTypography={true}
											className={collapseItemText}
										/>
									</a>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<div
										className={cx(classes.itemLink, classes.userCollapseLinks)}
										onClick={doLogout}
									>
										<span className={collapseItemMini}>
											<ExitToAppIcon />
										</span>
										<ListItemText
											primary={intl.fm('navigation.entries.user.logout')}
											disableTypography={true}
											className={collapseItemText}
										/>
									</div>
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		)

		const links = (
			<List className={classes.list}>
				{routes.map((route, key) => {
					if (route.redirect) {
						return null
					}

					if (route.collapse) {
						const navLinkClassesCollapse = cx(classes.itemLink, {
							[classes.collapseActive]: this.activeRoute(route.path)
						})

						return (
							<ListItem key={key} className={classes.item}>
								<NavLink
									to={'#'}
									className={navLinkClassesCollapse}
									onClick={() => this.openCollapse(route.state)}
								>
									<ListItemIcon className={itemIcon}>
										{route.icon ? (
											<route.icon />
										) : route.faIcon ? (
											<FontAwesomeIcon
												className={classes.sideBarfaIcon}
												icon={route.faIcon}
											/>
										) : route.mini ? (
											<span className={classes.miniText}>{route.mini}</span>
										) : null}
									</ListItemIcon>
									<ListItemText
										primary={route.name}
										secondary={
											<b
												className={cx(caret, {
													[classes.caretActive]: this.state[route.state]
												})}
											/>
										}
										disableTypography={true}
										className={itemText}
									/>
								</NavLink>
								<Collapse in={this.state[route.state]} unmountOnExit>
									<List className={cx(classes.list, classes.collapseList)}>
										{route.routes.map((subRoute, key) => {
											if (
												subRoute.redirect ||
												!subRoute.name || !subRoute.mini
											) {
												return null
											}
											const subNavLinkClasses = cx(classes.collapseItemLink, {
												[classes[color]]: this.activeRoute(subRoute.path)
											})
											return (
												<ListItem key={key} className={classes.collapseItem}>
													<NavLink
														to={subRoute.path}
														className={subNavLinkClasses}
													>
														<span className={collapseItemMini}>
															{subRoute.mini}
														</span>
														<ListItemText
															primary={subRoute.name}
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											)
										})}
									</List>
								</Collapse>
							</ListItem>
						)
					}
					const navLinkClasses = cx(classes.itemLink, {
						[classes[color]]: this.activeRoute(route.path)
					})

					return (
						<ListItem key={key} className={classes.item}>
							<NavLink to={route.path} className={navLinkClasses}>
								<ListItemIcon className={itemIcon}>
									{route.icon ? (
										<route.icon />
									) : (
										<span className={classes.miniText}>{route.mini}</span>
									)}
								</ListItemIcon>
								<ListItemText
									primary={route.name}
									disableTypography={true}
									className={itemText}
								/>
							</NavLink>
						</ListItem>
					)
				})}

				<ListItem className={classes.item}>
					<a
						href={this.props.helpUrl}
						target="_blank"
						rel="noopener noreferrer"
						className={classes.itemLink}
					>
						<ListItemIcon className={itemIcon}>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText
							primary={intl.fm('navigation.entries.help')}
							disableTypography={true}
							className={itemText}
						/>
					</a>
				</ListItem>
			</List>
		)

		const logoNormal = cx(classes.logoNormal, {
			[classes.logoNormalSidebarMini]: isMiniActive,
			[classes.logoNormalSidebarMiniRTL]: rtlActive && isMiniActive,
			[classes.logoNormalRTL]: rtlActive
		})

		const logoClasses = cx(classes.logo, {
			[classes.whiteAfter]: bgColor === 'white'
		})

		const sidebarMinimize = cx(classes.sidebarMinimize, classes.itemLink, {
			[classes.sidebarMinimizeRTL]: rtlActive
		})

		const brand = (
			<div className={logoClasses}>
				{isMiniActive ? (
					<div>
						<img src={logoMini} alt="logo" className={classes.imgMini} />
					</div>
				) : (
					<div>
						<img src={logo} alt="logo" className={classes.img} />
					</div>
				)}
				<div className={logoNormal}>{logoText}</div>
			</div>
		)

		const brandBottom = (
			<div className={logoClasses}>
				{isMiniActive ? (
					<div>
						<img src={logoMiniBottom} alt="logo" className={classes.img} />
					</div>
				) : (
					<div>
						<img src={logoBottom} alt="logo" className={classes.img} />
					</div>
				)}
				<div className={logoNormal}>{logoTextBottom}</div>
				{this.allowMinified() && (
					<div className={sidebarMinimize} onClick={this.props.sidebarMinimize}>
						{miniActive ? (
							<div className={classes.itemText}>
								<FontAwesomeIcon
									className={cx(classes.itemIcon, classes.sideBarfaIcon)}
									icon="angle-double-right"
								/>
								&nbsp;
							</div>
						) : (
							<div className={classes.itemText}>
								<FontAwesomeIcon
									className={cx(classes.itemIcon, classes.sideBarfaIcon)}
									icon="angle-double-left"
								/>
								{intl.fm('common.labels.collapseSidebar')}
							</div>
						)}
					</div>
				)}
			</div>
		)

		const drawerPaper = cx(classes.drawerPaper, {
			[classes.drawerPaperMini]: isMiniActive,
			[classes.drawerPaperRTL]: rtlActive
		})

		const sidebarWrapper = cx(classes.sidebarWrapper, {
			[classes.drawerPaperMini]: isMiniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]:
				navigator.platform.indexOf('Win') > -1
		})

		return (
			<div ref="mainPanel">
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={rtlActive ? 'right' : 'left'}
						open={this.props.open}
						classes={{
							paper: cx(drawerPaper, classes[bgColor + 'Background'])
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							// Better open performance on mobile.
							keepMounted: true
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<HeaderLinks rtlActive={rtlActive} />}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: 'url(' + image + ')' }}
							/>
						) : null}
						{brandBottom}
					</Drawer>
				</Hidden>
				<Hidden smDown>
					<Drawer
						onMouseOver={() => this.setState({ miniActive: true })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={rtlActive ? 'right' : 'left'}
						variant="permanent"
						open
						classes={{
							paper: cx(drawerPaper, classes[bgColor + 'Background'])
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: 'url(' + image + ')' }}
							/>
						) : null}
						{brandBottom}
					</Drawer>
				</Hidden>
			</div>
		)
	}
}

Sidebar.defaultProps = {
	bgColor: 'blue'
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		'white',
		'red',
		'orange',
		'green',
		'blue',
		'purple',
		'rose'
	]),
	logo: PropTypes.string,
	logoMini: PropTypes.string,
	logoText: PropTypes.string,
	logoBottom: PropTypes.string.isRequired,
	logoMiniBottom: PropTypes.string.isRequired,
	logoTextBottom: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	realm: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
	helpUrl: state.branding.helpUrl
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchBranding: brandingActions.fetchBranding
		},
		dispatch
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(sidebarStyle),
	withIntl,
	withWidth()
)(Sidebar)
