import { Button } from "@hlcr/mui/Button";
import { Upload } from "@hlcr/mui/Upload";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles } from "@material-ui/core";
import { Line } from "rc-progress";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { commitUpdateRequest, createUpdateRequest } from "actions/resources";
import { infoColor, roseColor } from "assets/jss/material-dashboard-pro-react";
import { isNumeric } from "helper/number";
import {
	UPLOAD_STATES,
	UploadState,
	ResourceType,
} from "models/ResourceUpload";

import { commonStyles } from "./style";

const HASH_WORKER_PATH = "/workers/hash-worker.js";
enum HashWorkerMessageType {
	PROGRESS="progress",
	COMPLETE="complete"
}
interface HashWorkerMessage {
	type: HashWorkerMessageType;
	content: number | string;
}
let hashWorker: Worker;

interface CreateUpdateRequestResult {
	method: "PUT";
	url: string;
	headers: { [key: string]: string };
}

let file: File | undefined = undefined;
interface FileUploadProps {
	resourceType: ResourceType;
	uuid: string;
	applyHash: (hash: string) => void;
}
export const FileUpload = ({ resourceType, uuid, applyHash }: FileUploadProps) => {
	const classes = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();

	const [ fileName, setFileName ] = useState("");
	const [ size, setSize ] = useState<number>();
	const [ hash, setHash ] = useState("");
	const [ hashProgress, setHashProgress ] = useState<number>();
	const [ uploadState, setUploadState ] = useState(UploadState.IDLE);
	const [ uploadProgress, setUploadProgress ] = useState(0);

	useEffect(() => {
		if (!hash || uploadState !== UploadState.UPLOADED) {
			return;
		}
		setUploadState(UploadState.COMMITTING);
		dispatch(commitUpdateRequest(resourceType.toUpperCase() as ResourceType, uuid, hash, () => {
			setUploadState(UploadState.DONE);
			applyHash(hash);
		}));
	}, [ hash, uploadState ]);

	const onHashWorkerMessage = (event: MessageEvent<HashWorkerMessage>) => {
		if (!event?.data) {
			return;
		}
	
		const hashWorkerMessage = event.data;
		switch (hashWorkerMessage.type) {
			case HashWorkerMessageType.PROGRESS: {
				setHashProgress(hashWorkerMessage.content as number);
				break;
			}
			case HashWorkerMessageType.COMPLETE: {
				setHash(hashWorkerMessage.content as string);
				break;
			}
		}
	};

	const initHashWorker = () => {
		hashWorker?.terminate();
		hashWorker = new Worker(HASH_WORKER_PATH);
		hashWorker.onmessage = onHashWorkerMessage;
	};

	useEffect(() => {
		// Component Mount
		initHashWorker();
		// Component Unmount
		return () => {
			hashWorker.terminate();
			file = undefined;
		};
	}, []);



	const calculateHash = () => hashWorker.postMessage(file);
	const onDrop = (droppedFile?: File | null) => {
		if (!droppedFile) {
			return;
		}

		file = droppedFile;

		setFileName(file.name);
		setSize(file.size);
		setHash("");
		setHashProgress(0);
		setUploadProgress(0);
		setUploadState(UploadState.WAITING_FOR_USER);

		calculateHash();
	};

	const cancelHashCalculation = () => {
		initHashWorker();
		setHashProgress(undefined);
	};

	const handleUploadFileClick = () => uploadFile(resourceType, uuid);

	const uploadFile = (resourceType: ResourceType, uuid: string) => {
		setUploadState(UploadState.INIT);
		setUploadProgress(0);
	
		const onCreateUpdateRequest = (request: CreateUpdateRequestResult) => {
			const xhr = new XMLHttpRequest();
			xhr.open(request.method, request.url, true);
			if (typeof request.headers === "object") {
				Object.keys(request.headers).forEach((key) => xhr.setRequestHeader(key, (request.headers[key])));
			}
			xhr.upload.addEventListener("load", () => {
				setUploadState(UploadState.UPLOADED);
			});
			xhr.upload.addEventListener("progress", event => {
				if (event.lengthComputable) {
					const progress = (event.loaded / event.total) * 100;
					setUploadProgress(progress);
				}
			});
			xhr.upload.addEventListener("error", event => {
				console.error(event);
				setUploadState(UploadState.ERROR);
			});
			xhr.upload.addEventListener("abort", event => {
				console.error(event);
				setUploadState(UploadState.ABORTED);
			});

			xhr.upload.addEventListener("timeout", event => {
				console.error(event);
				setUploadState(UploadState.TIMEOUT);
			});

			xhr.send(file);
			setUploadState(UploadState.UPLOADING);
		};

		dispatch(createUpdateRequest(resourceType.toUpperCase() as ResourceType, uuid, onCreateUpdateRequest));
	};

	return (
		<div>
			<Upload
				type="file"
				handleRawFile={onDrop}
				disabled={
					(hashProgress !== undefined && hashProgress < 100) ||
					[ UploadState.INIT, UploadState.UPLOADING, UploadState.UPLOADED, UploadState.COMMITTING ].includes(uploadState)
				}
			/>
			<div className={classes.uploadMonitor}>
				<div className={classes.labelContainer}>
					<span className={classes.labelTitle}>FILE NAME</span>
					{fileName}
				</div>
				<div className={classes.labelContainer}>
					<span className={classes.labelTitle}>SIZE</span>
					{size !== undefined && `${Math.round((Number(size) / 1024000) * 100) / 100} MB`}
				</div>
				<div className={classes.labelContainer}>
					<span className={classes.labelTitle}>MD5 HASH</span>
					{hash
						? (hash.toLowerCase())
						: hashProgress !== undefined
							? (
									<span>
										{Math.round(hashProgress)}%
										<Button
											onClick={cancelHashCalculation}
											color="warningNoBackground"
											customClass={classes.hashCancelButton}
										>
											cancel
										</Button>
									</span>
								)
							: file ? (
								<Button
									onClick={calculateHash}
									color="warningNoBackground"
									customClass={classes.hashCancelButton}
								>
									Restart
								</Button>
							) : null}
				</div>
				<Line
					percent={isNumeric(hashProgress) ? hashProgress : 0}
					trailColor="#eee"
					strokeColor={infoColor}
				/>
				<div className={classes.labelContainer}>
					<span className={classes.labelTitle}>UPLOAD PROGRESS</span>
					{intl.fm(UPLOAD_STATES[uploadState].title, "", { uploadProgress })}
				</div>
				<Line
					percent={uploadProgress}
					trailColor="#eee"
					strokeColor={roseColor}
				/>
				<Button
					onClick={handleUploadFileClick}
					disabled={
						uploadState === UploadState.IDLE ||
						uploadState === UploadState.INIT ||
						uploadState === UploadState.UPLOADING ||
						uploadState === UploadState.UPLOADED ||
						uploadState === UploadState.COMMITTING
					}
					color="rose"
				>
						Upload File
				</Button>
			</div>
		</div>
	);
};

const useStyles = makeStyles({
	...commonStyles,
	uploadMonitor: { marginTop: 24 },
	hashCancelButton: {
		padding: 0,
		margin: "0 0 0 15px",
	},
});
