import { FileMeta, Resource, ResourceBase } from "models/ResourceUpload";


export interface BasicResourceState {
	pending?: true;
	basic: ResourceBase;
}

export interface DetailedResourceState {
	pending: false;
	basic: Resource;
	fileMeta: FileMeta;
	composeFile: string
	managerConfig: string;
}

export type ResourceState = BasicResourceState | DetailedResourceState;
export interface ResourceStateData {
	[uuid: string]: ResourceState;
}
export interface ResourcesState {
	pending?: boolean;
	data: ResourceStateData;
}

export const resourceInitState: ResourcesState = { data: {} };
