import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";

export enum UploadState {
	IDLE = "IDLE",
	WAITING_FOR_USER = "WAITING_FOR_USER",
	INIT = "INIT",
	UPLOADING = "UPLOADING",
	UPLOADED = "UPLOADED",
	COMMITTING = "COMMITTING",
	DONE = "DONE",
	ERROR = "ERROR",
	ABORTED = "ABORTED",
	TIMEOUT = "TIMEOUT",
}

export const UPLOAD_STATES: EnumDetail<UploadState, EnumBase> = {
	IDLE: { title: "upload.status.idle.title" },
	WAITING_FOR_USER: { title: "upload.status.waitingForUser.title" },
	INIT: { title: "upload.status.init.title" },
	UPLOADING: { title: "upload.status.uploading.title" },
	UPLOADED: { title: "upload.status.waitingForHash.title" },
	COMMITTING: { title: "upload.status.committing.title" },
	DONE: { title: "upload.status.done.title" },
	ERROR: { title: "upload.status.error.title" },
	ABORTED: { title: "upload.status.aborted.title" },
	TIMEOUT: { title: "upload.status.timeout.title" },
};


export enum ResourceType {
	DOCKER = "DOCKER",
	FILE = "FILE",
	VM = "VM"
}


interface ResourceTypeEnum extends EnumBase {
	fileType: "archive" | "file" | "image";
	nameRegex: RegExp;
	allowedExtension: string;
	fileNameHelpText: string;
}

export const RESOURCE_TYPES: EnumDetail<ResourceType, ResourceTypeEnum> = {
	DOCKER: {
		title: "resource.type.docker",
		nameRegex: /^[A-Za-z0-9\-_]+$/,
		allowedExtension: ".tar.gz",
		fileNameHelpText: "resource.type.docker.fileNameHelpText",
		fileType: "archive",
	},
	FILE: {
		title: "resource.type.file",
		nameRegex: /^[A-Za-z0-9\-_]+\.[A-Za-z0-9.]+$/,
		allowedExtension: "*",
		fileNameHelpText: "resource.type.file.fileNameHelpText",
		fileType: "file",
	},
	VM: {
		title: "resource.type.vm",
		nameRegex: /^[A-Za-z0-9\-_]+[^. ]$/,
		allowedExtension: ".ova",
		fileNameHelpText: "resource.type.vm.fileNameHelpText",
		fileType: "image",
	},
};

export enum DockerAppType {
	IDOCKER = "IDOCKER",
	RDOCKER = "RDOCKER",
	CUSTOM = "CUSTOM",
}

export type AppLinkProtocol = "http" | "tcp"

export interface DockerManagerConfig {
	name: string;
	type: string;
	dynFqdn: boolean;
	fqdn: string;
	expireTime: number;
	dockerType: "docker-compose";
	singleton: boolean;
	ipAccess: "direct" | "nat";
	port: number;
	protocol: "http" | "socket";
	link: AppLinkProtocol;
	container: string;
	containeryml: string;
	vars: string;
	network: string;
	egonetwork: boolean;
	challengeType: "noGN" | "dynamicGN";
	gnCreation?: "docker-manager";
	gnDeploy?: "file";
	goldNugget: string;
	withCredentials: boolean;
	dynUser: boolean;
	dynPassword: boolean;
	staticUsername?: string;
	staticPassword?: string;
}

export interface ManagerConfig {
	obj: DockerManagerConfig;
	text: string;
}

export interface ResourceBase {
	uuid: string;
	type: ResourceType;
	displayName: string;
}

export interface Resource extends ResourceBase {
	name: string;
	resourceManagerParameters: {[key: string]: string};
	userProperties: {[key: string]: string};
	hierarchy?: number;
}

export interface ComposeFile {
	text: string;
}


export const createManagerConfig = (
	uuid: string,
	appType: DockerAppType,
	resourceName: string,
	displayName: string,
	dynamicFlag: boolean,
	appProtocol?: string,
	appPort?: number,
	appLinkProtocol?: AppLinkProtocol,
) => JSON.stringify({
	name: displayName,
	type: uuid,
	dynFqdn: true,
	fqdn: "",
	expireTime: 60,
	dockerType: "docker-compose",
	singleton: false,
	port: appPort || 80,
	ipAccess: appType === DockerAppType.RDOCKER ? "direct" : "nat",
	protocol: appType === DockerAppType.RDOCKER ? "socket" : (appProtocol || "http"),
	link: (appType === DockerAppType.RDOCKER && appLinkProtocol) || "tcp",
	container: `REGISTRY_BASE_URL/${resourceName}:stable`,
	containeryml: `${uuid}.yml`,
	vars: "",
	network: resourceName,
	egonetwork: true,
	challengeType: dynamicFlag ? "dynamicGN" : "noGN",
	gnCreation: dynamicFlag ? "docker-manager" : undefined,
	gnDeploy: dynamicFlag ? "file" : undefined,
	goldNugget: "",
	withCredentials: false,
	dynUser: false,
	dynPassword: false,
	staticUsername: "",
	staticPassword: "",
}, null, 2);

export const createComposeFile = (uuid: string, appType: DockerAppType, resourceName: string, dynamicGN: boolean, protocol: string = "http", port: number = 80, externalNet?: string): string => {
	let result = `version: '2.3'

services:
  ${resourceName}-hobo:
    image: REGISTRY_BASE_URL/${resourceName}:stable
    hostname: 'hobo'
    environment:
      - "domainname=${appType === DockerAppType.RDOCKER ? "r" : "i"}docker.REALM_DOMAIN_SUFFIX"
    cpus: 1
    mem_limit: 128M`;

	if (appType === DockerAppType.IDOCKER) {
		result += `
    labels:
      - "traefik.port=${port}"
      - "traefik.frontend.rule=Host:hobo.idocker.REALM_DOMAIN_SUFFIX"
      - "traefik.protocol=${protocol}"`;
	} else if (appType === DockerAppType.RDOCKER) {
		result += `
    ports:
      - "${port}:${port}"`;
	}

	if (dynamicGN) {
		result += `
    volumes:
      - ./${uuid}.gn:/goldnugget/${uuid}.gn`;
	}

	if (externalNet) {
		result += `
    networks:
      - ${externalNet}

networks:
  ${externalNet}:
    external: true`;
	}

	return result;
};

interface Error {
	field: string;
	defaultMessage: string;
}

export interface ResponseResult {
	errors?: Error[];
}

export interface FileMeta {
	name: string;
	hash?: string;
}
