import { Button } from "@hlcr/mui/Button";
import { makeStyles } from "@material-ui/core";
import { ChangeEvent, useState } from "react";

import CustomInput from "components/CustomInput/CustomInput";

export const KeyValueInputField = ({
	oldKey,
	oldValue,
	removeProperty,
	setProperty,
	replaceProperty,
}: {
	oldKey: string;
	oldValue: string;
	removeProperty: (key: string) => void;
	setProperty: (key: string, value: any) => void;
	replaceProperty: (oldKey: string, newKey: string) => void;
}) => {
	const classes = useStyles();
	const [ newKey, setNewKey ] = useState<string>(oldKey);
	const [ newValue, setNewValue ] = useState<string>(oldValue);
	return (
		<div>
			<CustomInput
				labelText={"Key"}
				inputProps={{
					value: newKey,
					onFocus: (event: ChangeEvent<any>) => setNewKey(event.target.value),
					onChange: (event: ChangeEvent<any>) => setNewKey(event.target.value),
					onBlur: (event: ChangeEvent<any>) => {
						replaceProperty(oldKey, event.target.value);
						setNewKey(newKey);
					},
				}}
			/>
			<CustomInput
				labelText={"Value"}
				inputProps={{
					value: newValue,
					onChange: (event: ChangeEvent<any>) => {
						setNewValue(event.target.value);
						setProperty(oldKey, event.target.value);
					},
				}}
			/>
			<Button onClick={() => removeProperty(oldKey)} color="dangerNoBackground" size="xs" customClass={classes.deleteButton}>
				Remove
			</Button>
		</div>
	);
};

const useStyles = makeStyles({
	deleteButton: {
		margin: "0 0 0 15px",
		padding: "5px",
	},
});
