import brandingActions, {
	defaultBranding,
	FETCH_BRANDING_CONFIG
} from '../actions/branding'

export default middleware => next => async action => {
	const nextAction = next(action)

	if (action.type === FETCH_BRANDING_CONFIG) {
		try {
			const response = await fetch('/config/branding.json')
			const json = await response.json()
			for (let key of Object.keys(defaultBranding)) {
				if (!json.hasOwnProperty(key) || !json[key]) {
					json[key] = defaultBranding[key]
				}
			}
			middleware.dispatch(brandingActions.setBranding(json))
		} catch (e) {
			return middleware.dispatch(brandingActions.setBranding(defaultBranding))
		}
	}

	return nextAction
}
