import { resultOf } from "@hlcr/core/async";
import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import {  Dispatch, AnyAction } from "redux";

import { BASE_URL } from "actions/resources";
import { Resource, ResourceType } from "models/ResourceUpload";
import { DetailedResourceState } from "reducers/resourceState";

import { createHeaders, processResult, Results } from "./util";


export const updateResource = async (action: AnyAction, data: DetailedResourceState, dispatch: Dispatch, onSuccess?: (result?: DetailedResourceState) => void) => {
	const init: RequestInit = {
		method: "put",
		headers: createHeaders(),
		credentials: "same-origin",
	};
	const { basicError, basicResponse } = await updateCommonData(action.resourceType, action.resource, data.basic, init);
	const { composeFileError, dockerManagerError, composeFileResponse, dockerManagerResponse } = await updateDockerData(action.resourceType, action.resource, data.managerConfig, data.composeFile, init);
	const results: Results = [
		{ response: basicResponse, error: basicError },
		{ response: composeFileResponse, error: composeFileError },
		{ response: dockerManagerResponse, error: dockerManagerError },
	];

	const result = await processResult(results, (results: Results) => ({}), HttpStatusCode.NO_CONTENT, dispatch, action.onFailure, action.suppressErrorNotification);
	if(result) {
		onSuccess?.();
	}
	return result;
};

async function updateCommonData(resourceType: ResourceType, uuid: string, basic: Resource, init: RequestInit) {
	const resourceTypePlural = `${resourceType.toLowerCase()}s`;
	const [ basicError, basicResponse ] = await resultOf(fetch(`${BASE_URL}/${resourceTypePlural}/${uuid}`, { ...init, body: JSON.stringify(basic) }));

	return { basicError, basicResponse };
}


export async function updateDockerData(resourceType: ResourceType, uuid: string, dockerManager: string, dockerCompose: string, init: RequestInit) {
	if (resourceType !== ResourceType.DOCKER) {
		const okResponse = { status: HttpStatusCode.NO_CONTENT, json: () => Promise.resolve(undefined) } as Pick<Response, "status" | "json">;
		return { composeFileError: null, dockerManagerError: null, composeFileResponse: okResponse, dockerManagerResponse: okResponse };
	}

	const [ [ composeFileError, composeFileResponse ], [ dockerManagerError, dockerManagerResponse ] ] = await Promise.all(
		[
			resultOf(fetch(`${BASE_URL}/dockers/${uuid}/compose`, { ...init, body: JSON.stringify(dockerCompose) })),
			resultOf(fetch(`${BASE_URL}/dockers/${uuid}/manager`, { ...init, body: dockerManager })),
		],
	);
	return { composeFileError, dockerManagerError, composeFileResponse, dockerManagerResponse };
}
