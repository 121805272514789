import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { keyByValue } from "@hlcr/core/enum";

import { RESOURCE_TYPES, ResourceType } from "models/ResourceUpload";
import { DetailedResourceState } from "reducers/resourceState";

import { API_REQUEST } from "./api";


export enum ResourceActionType {
	FETCH_RESOURCE_OVERVIEW = "FETCH_RESOURCE_OVERVIEW",
	RESOURCE_OVERVIEW_RESPONSE = "RESOURCE_OVERVIEW_RESPONSE",
	//
	CREATE_RESOURCE = "CREATE_RESOURCE",
	CREATE_RESOURCE_RESPONSE = "CREATE_RESOURCE_RESPONSE",
	FETCH_RESOURCE = "FETCH_RESOURCE",
	RESOURCE_RESPONSE = "RESOURCE_RESPONSE",
	UPDATE_RESOURCE = "UPDATE_RESOURCE",
	//
	CREATE_FILE_UPLOAD_REQUEST = "CREATE_FILE_UPLOAD_REQUEST",
	COMMIT_UPDATE_REQUEST = "COMMIT_UPDATE_REQUEST",
}


export const RESOURCE_ACTIONS: EnumDetail<ResourceActionType, any> = { // any was ActionTypeEnum<ResourceActionType>, but that shit with this EnumBase class is not working, and I have no idea how this ever was able to build...
	FETCH_RESOURCE_OVERVIEW: {
		isRequest: true,
		responseActionType: ResourceActionType.RESOURCE_OVERVIEW_RESPONSE,
	},
	RESOURCE_OVERVIEW_RESPONSE: { isRequest: false },
	//
	CREATE_RESOURCE: {
		isRequest: true,
		responseActionType: ResourceActionType.RESOURCE_RESPONSE,
	},
	CREATE_RESOURCE_RESPONSE: { isRequest: false },
	FETCH_RESOURCE: {
		isRequest: true,
		responseActionType: ResourceActionType.RESOURCE_RESPONSE,
	},
	UPDATE_RESOURCE: {
		isRequest: true,
		responseActionType: ResourceActionType.RESOURCE_RESPONSE,
	},
	RESOURCE_RESPONSE: { isRequest: false },
	//
	CREATE_FILE_UPLOAD_REQUEST: { isRequest: false },
	COMMIT_UPDATE_REQUEST: { isRequest: false },
};


export const BASE_URL = "/api/resources";

export const fetchResources = () => ({
	type: ResourceActionType.FETCH_RESOURCE_OVERVIEW,
	url: BASE_URL,
	method: "get",
});

export const createResource = (type: ResourceType, resource: DetailedResourceState, onSuccess?: (uuid: string) => void) => ({
	type: ResourceActionType.CREATE_RESOURCE,
	resourceType: type,
	method: "post",
	body: resource,
	successNotification: "New Resource Created",
	onSuccess: (resource: DetailedResourceState) => onSuccess?.(resource.basic.uuid),
});

export const fetchResource = (type: ResourceType, uuid: string, onSuccess?: (resource: DetailedResourceState) => void) => ({
	type: ResourceActionType.FETCH_RESOURCE,
	resourceType: type,
	method: "get",
	resource: uuid,
	onSuccess,
});

export const updateResource = (type: ResourceType, uuid: string, resource: DetailedResourceState) => ({
	type: ResourceActionType.UPDATE_RESOURCE,
	resourceType: type,
	method: "get",
	resource: uuid,
	body: resource,
	successNotification: "Resource Saved",
});

export const fetchFile = (type: ResourceType, uuid: string) => {
	return `${BASE_URL}/${type.toLowerCase()}s/${uuid}/${RESOURCE_TYPES[keyByValue(ResourceType, type.toUpperCase() as ResourceType)].fileType}`;
};
export const createUpdateRequest = (type: ResourceType, uuid: string, onSuccess?: (updateRequest: any) => void) => ({
	type: API_REQUEST,
	url: `${BASE_URL}/${type.toLowerCase()}s/${uuid}/${RESOURCE_TYPES[keyByValue(ResourceType, type)].fileType}/`,
	method: "post",
	onSuccess,
});

export const commitUpdateRequest = (type: ResourceType, uuid: string, hash: string, onSuccess?: () => void) => ({
	type: API_REQUEST,
	url: `${BASE_URL}/${type.toLowerCase()}s/${uuid}/${RESOURCE_TYPES[keyByValue(ResourceType, type)].fileType}/meta`,
	method: "put",
	body: { hash: hash },
	onSuccess,
});
