import { MessageType } from "models/Message"

export const API_REQUEST = 'API_REQUEST'
export const API_RESULT = 'API_RESULT'
export const API_REMOVE = 'API_REMOVE'
export const SAVE_AUTH_TOKEN = 'SAVE_AUTH_TOKEN'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const saveApiResult = (resource, data, type) => ({ type, resource, data })

export const addNotification = (
	id,
	message,
	messageType = MessageType.ERROR
) => ({
	type: ADD_NOTIFICATION,
	id,
	message,
	messageType
})

export const removeNotification = id => ({
	type: REMOVE_NOTIFICATION,
	id
})
