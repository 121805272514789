import { keycloak } from "@hlcr/ui/auth";
import "chartist/dist/chartist.js";

import "assets/scss/material-dashboard-pro-react.scss";
import "helper/icons";
import "polyfills.js";
import { createApp } from "App";
import { isLocalServerEnvironment } from "ProcessEnvironment";


// lazily load app if authenticated
const fakeCookie = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjM4Z29ZT3BNdTV1b0RfZ29xd1piZTFoYV9vd1FVZDhvMUZJODEwU0p5UGcifQ.eyJqdGkiOiJhZjBjMmQ0NS0yMDMxLTQ0Y2UtYjMzZC0zZDM2MjJhZmRkZjQiLCJleHAiOjE1Mjg4ODI4MzYsIm5iZiI6MCwiaWF0IjoyNTI4ODgyNTM2LCJpc3MiOiJodHRwczovL2F1dGguaWRvY2tlci5oYWNraW5nLWxhYi5jb20vYXV0aC9yZWFsbXMvZGV2ZWxvcG1lbnQiLCJhdWQiOiJjZXMiLCJzdWIiOiJhYjg0MmMwOC1iOWNmLTRiNzgtOGU5Mi04MzJhZDU1YzQxMTgiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjZXMiLCJub25jZSI6ImFjZGQyZTQ4LWRhYTUtNDQ5YS1iODcwLTIzODQ5ZGJiNzNkNiIsImF1dGhfdGltZSI6MTUyODg3ODc0NSwic2Vzc2lvbl9zdGF0ZSI6IjA3MjI4YTY4LWQ4MDAtNGE2YS1iZjFiLTQxM2Y4MzFhNjI2OCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdCIsImh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJyZXMiOnsicm9sZXMiOlsiY29tcG9zaXRlX3Jlc291cmNlcyIsInJlc291cmNlcyJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwicHJlZmVycmVkX3VzZXJuYW1lIjoiY2VzX2RldmVsb3BlciIsImVtYWlsIjoiY2VzX2RldmVsb3BlckBjcnlzdGFsLWN1YmUuY2gifQ.BGYmj4zcFhOl1l1oAwv3_zuK3EDEgv3MmvKOVAQiu5s"; // eslint-disable-line max-len -- mocked jwt token
keycloak.init(() => createApp(), isLocalServerEnvironment() ? fakeCookie : undefined);
