import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import iconCardStyle from 'assets/jss/material-dashboard-pro-react/components/iconCardStyle.jsx'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

function IconCard({ ...props }) {
	const {
		classes,
		title,
		content,
		iconColor,
		category,
		footer,
		plain,
		customCardContentClass,
		helpLink
	} = props
	const cardClasses = cx(classes.card, {
		[classes.cardPlain]: plain
	})
	const cardContentClasses = cx(classes.cardContent, customCardContentClass)
	return (
		<Card className={cardClasses}>
			<CardHeader
				classes={{
					root: cx(classes.cardHeader, classes[iconColor + 'CardHeader']),
					avatar: classes.cardAvatar
				}}
				avatar={
					props.icon ? (
						<props.icon className={classes.cardIcon} />
					) : (
						<FontAwesomeIcon
							className={classes.cardFaIcon}
							icon={props.faIcon}
						/>
					)
				}
			/>
			<CardContent className={cardContentClasses}>
				<h4 className={classes.cardTitle}>
					{title}
					{helpLink ? (
						<a href={helpLink} target="_blank" rel="noopener noreferrer">
							<HelpIcon className={classes.helpIcon} />
						</a>
					) : null}
					{category !== undefined ? (
						<small className={classes.cardCategory}>{category}</small>
					) : null}
				</h4>
				{content}
			</CardContent>
			{footer !== undefined ? (
				<div className={classes.cardFooter}>{footer}</div>
			) : null}
		</Card>
	)
}

IconCard.defaultProps = {
	iconColor: 'purple'
}

IconCard.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	faIcon: PropTypes.string,
	iconColor: PropTypes.oneOf([
		'orange',
		'green',
		'red',
		'blue',
		'purple',
		'rose'
	]),
	title: PropTypes.node,
	category: PropTypes.node,
	content: PropTypes.node,
	footer: PropTypes.node,
	plain: PropTypes.bool,
	customCardContentClass: PropTypes.string
}

export default withStyles(iconCardStyle)(IconCard)
